.login-wrapper {
  /* background-color: #637d63; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets//bg1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.login-wrapper .login {
  position: fixed;
  right: 0;
  background-color: #ffffff;
  width: 500px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-wrapper .login-logo {
  margin-bottom: 20px;
}

.login-wrapper .log-in-header {
  font-size: 25px;
  font-weight: 600;
}

.login-wrapper .login-sections {
  margin: 25px;
}

.login-wrapper .login-sections-error {
  margin: 40px;
}

.login-wrapper .input-fields {
  width: 300px;
}

.login-wrapper .login-btn {
  box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14),
    0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
  background-color: #a7ce3b;
  color: #ffffff;
  cursor: pointer;
}

.login-wrapper .login-btn:hover {
  box-shadow: 0 5px 10px -6px #333;
  background-color: #333;
}
