.search-pnr-wrapper {
  background-color: #ffffff;
  width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
}

.search-pnr-wrapper .heading {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 10px;
}

.search-pnr-wrapper .search-pnr {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-pnr-wrapper .search-pnr .search-pnr-element-wrapper {
  display: flex;
  margin: 10px 0px;
}

.search-pnr-wrapper
  .search-pnr
  .search-pnr-element-wrapper
  .search-pnr-element {
  margin: 5px 20px;
}

.search-pnr-wrapper .search-pnr .search-pnr-element-wrapper .input-element {
  width: 220px;
}

.search-pnr-wrapper .search-pnr .search-btn {
  margin: 10px 0px;
}

.search-pnr-wrapper .view-details {
  margin: 40px 10px;
}

.search-pnr-wrapper .view-details .details-container {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.36);
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0px;
}

.search-pnr-wrapper .view-details .details-container .confirm-text-Pending,
.search-pnr-wrapper .view-details .details-container .confirm-text-Cancelled,
.search-pnr-wrapper .view-details .details-container .confirm-text-UnKnown,
.search-pnr-wrapper .view-details .details-container .confirm-text-Confirmed {
  display: flex;
  justify-content: end;
}

.search-pnr-wrapper
  .view-details
  .details-container
  .confirm-text
  span {
  border: 1px solid #f77f00;
  border-radius: 5px;
  padding: 1px 3px;
  font-weight: 500;
  color: #f77f00;
}


.search-pnr-wrapper
  .view-details
  .details-container
  .confirm-text-Pending
  span {
  border: 1px solid #f77f00;
  border-radius: 5px;
  padding: 1px 3px;
  font-weight: 500;
  color: #f77f00;
}

.search-pnr-wrapper
  .view-details
  .details-container
  .confirm-text-Cancelled
  span {
  border: 1px solid #d90429;
  border-radius: 5px;
  padding: 1px 3px;
  font-weight: 500;
  color: #d90429;
}

.search-pnr-wrapper
  .view-details
  .details-container
  .confirm-text-UnKnown
  span {
  border: 1px solid #f77f00;
  border-radius: 5px;
  padding: 1px 3px;
  font-weight: 500;
  color: #f77f00;
}

.search-pnr-wrapper
  .view-details
  .details-container
  .confirm-text-Confirmed
  .text {
  border: 1px solid #008b38;
  border-radius: 5px;
  padding: 1px 3px;
  font-weight: 500;
  color: #008b38;
}

.search-pnr-wrapper
  .view-details
  .details-container
  .confirm-text-Confirmed
  .img-wrapper {
  width: 8px;
  margin-right: 65px;
}

.search-pnr-wrapper
  .view-details
  .details-container
  .confirm-text-Confirmed
  img {
  height: 20px;
}

.search-pnr-wrapper .view-details .details-header {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-pnr-wrapper .view-details .details-container .details-row {
  display: flex;
  margin: 8px 0px;
}

.search-pnr-wrapper .view-details .details-container .details-row .detail {
  width: 250px;
  display: flex;
}

.search-pnr-wrapper .view-details .details-container .details-row .bookedOn {
  width: 280px;
}

.search-pnr-wrapper
  .view-details
  .details-container
  .details-row
  .detail
  .detail-data {
  font-weight: 600;
  margin-left: 5px;
}

.search-pnr-wrapper
  .view-details
  .details-container
  .details-row
  .detail
  .detail-data
  img {
  height: 15px;
  margin-bottom: 2px;
}

.search-pnr-wrapper .btn-row {
  display: flex;
  justify-content: center;
}

.search-pnr-wrapper .btn-row .btn {
  margin: 5px 10px 0px 10px;
}
