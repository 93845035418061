.available-balance-wrapper {
    background-color: #ffffff;
    padding: 20px;
    width: 1300px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.available-balance-wrapper .airline-balance-table-wrapper{
    margin: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.available-balance-wrapper .airline-balance-table-wrapper .airline-balance-table{
    width: 400px;
}

