.currency-conversion-wrapper {
  background-color: #ffffff;
  padding: 20px;
  width: 1150px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.currency-conversion-wrapper .currency-conversion-header {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 25px;
}

.currency-conversion-wrapper
  .currency-conversion-input-wrapper
  .currency-dd-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.currency-conversion-wrapper
  .currency-conversion-input-wrapper
  .currency-dd-wrapper
  .currency-dd {
  width: 200px;
  margin: 0px 20px;
}

.currency-conversion-wrapper
  .currency-conversion-input-wrapper
  .current-currency-rate {
  margin-bottom: 25px;
  font-weight: 500;
}

.currency-conversion-wrapper
  .currency-conversion-input-wrapper
.currency-conversion-input{
    display: flex;
    justify-content:center;
    margin-bottom: 25px;
}

.currency-conversion-wrapper
  .currency-conversion-input-wrapper
.currency-conversion-input div{
    width: 200px;
}
