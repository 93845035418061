.search-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-wrapper .search-flights-wrapper{
    background-color: rgba(255,255,255,1);
    padding: 20px;
    width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.search-flights-wrapper .heading {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  
  .search-flights-wrapper .search-airline-section,
  .search-flights-wrapper .search-trip-section,
  .search-flights-wrapper .search-origin-section,
  .search-flights-wrapper .search-destination-section,
  .search-flights-wrapper .search-departure-section,
  .search-flights-wrapper .search-return-section {
    width: 260px;
    margin: 15px;
  }

  .search-flights-wrapper .add-passenger .add-passenger-type {
    width: 175px;
    margin: 15px;
  }

  .search-flights-wrapper .search-dates-section ,
  .search-flights-wrapper .search-city-section,
  .search-flights-wrapper .add-passenger {
    display: flex;
  }
  
  .search-flights-wrapper .register-role-section {
    margin: 15px;
  }
  
  .search-flights-wrapper .search-btn-wrapper {
    margin: 15px;
  }