.booking-details-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
}

.booking-details-wrapper .flight-cards {
    display: flex;
    margin-top: 60px;
}

.booking-details-wrapper  .flight-cards-col {
    display: flex;
    /* flex-direction: column; */
}

.booking-details-wrapper .flight-cards .fare-section{
    border-radius: 5px;
    height: 150px;
    width: fit-content;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.36);
    margin: 20px 15px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.booking-details-wrapper .flight-cards .fare-section .header{
   font-size: 20px;
   font-weight: 500;
}

.booking-details-wrapper .flight-cards .fare-section .fare{
    font-size: 25px;
    font-weight: 500;
    display: flex;
    align-items: center;
 }

 .booking-details-wrapper .flight-cards .fare-section .fare .rupeeSvg{
    height: 25px;
    margin-top: 3px;
    margin-right: 3px;
 }

.booking-details-wrapper .flight-card-wrapper{
    display: flex;
    border-radius: 5px;
    height: 150px;
    width: fit-content;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.36);
    margin: 20px 15px;
}

.booking-details-wrapper .flight-card-wrapper .flight-name-details{
    border-right: 1px solid #e2e2e2;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center ;
    font-family: "Rubik-Regular";
    font-size: 13px;
    color: #333333
}

.booking-details-wrapper .flight-card-wrapper .flight-name-details .flight-logo img{
    height: 20px;
    width: 20px;
}

.booking-details-wrapper .flight-card-wrapper .flight-name-details .seperator{
    margin: 5px 0px; 
    border-top: 1px solid #e2e2e2;
    width: 100%;
}

.booking-details-wrapper .flight-card-wrapper .booking-class-section{
    border-right: 1px solid #e2e2e2;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center ;
    font-family: "Rubik-Regular";
    font-size: 13px;
    color: #333333
}

.booking-details-wrapper .flight-card-wrapper .flight-city-details{
    display: flex;
    align-items: center;
    padding: 15px;
    border-right: 1px solid #e2e2e2;
}

.booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-left,
.booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.booking-details-wrapper 
  .flight-card-wrapper
  .flight-city-details
  .flight-city-left
  .flight-city-code{
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .booking-details-wrapper 
  .flight-card-wrapper
  .flight-city-details
  .flight-city-right
  .flight-city-code{
    display: flex;
    justify-content: start;
    align-items: center;
  }

.booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-code{
   color: #999999;
   font-size: 18px;
   font-family: "Rubik-Regular";
}

.booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-time{
   color: #333333;
   font-weight: 700;
   font-size: 20px;
   font-family: "Rubik-Medium";
}

.booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-left .flight-city-time{
    margin-left: 5px;
 }

 .booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-right .flight-city-time{
    margin-right: 5px;
 }

.booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-name{
    font-family: "Rubik-Regular";
    color: #666666;
    font-size: 12px;
    display: flex;
}

.booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-left .flight-city-name{
    justify-content: right;
}

.booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-right .flight-city-name{
    justify-content: left;
}

.booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-seperator{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
}

.booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-seperator .line-seperator {
    width: 80px;
    border-bottom: 1px solid #cacaca;
}

.booking-details-wrapper .flight-card-wrapper .flight-city-details .duration-stop {
    border-left: 1px solid #cacaca;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    padding-left: 10px;
    height: 50px;
}

.booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-seperator .duration{
    font-weight: 600;
    font-size: 15px;
    font-family: "Rubik-Regular";
}

.booking-details-wrapper .flight-card-wrapper .flight-city-details .flight-city-seperator .stop {
    font-size: 10px;
    font-family: "Rubik-Medium";
    /* font-weight: 600; */
}

.booking-details-wrapper .flight-card-wrapper .flexi-selection {
    padding: 5px;
    width: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booking-details-wrapper .flight-card-wrapper .flexi-selection .feature{
    font-size: 10px;
    margin: 3px 0px;
  }
  
  .booking-details-wrapper .flight-card-wrapper .flexi-selection .feature img{
    width: 12px;
    margin-right: 5px;
  }

  .booking-details-wrapper .flight-card-wrapper .flexi-selection .header{
    font-weight: 500
  }

.booking-details-wrapper .contact-wrapper{
    display: flex;
    align-items: center;
}

.booking-details-wrapper .contact-wrapper .header{
    font-weight: 600;
}

.booking-details-wrapper .contact-wrapper .email-wrapper,
.booking-details-wrapper .contact-wrapper .mobileNo-wrapper,
.booking-details-wrapper .contact-wrapper .birthdate-wrapper{
    display: flex;
    align-items: center;
    margin: 20px;
}

.booking-details-wrapper .contact-wrapper .mobileNo-wrapper .country-code{
    width: 100px;
}

.booking-details-wrapper .contact-wrapper .email-wrapper{
    width: 300px;
}

.booking-details-wrapper .pax-details-wrapper{

}

.booking-details-wrapper .pax-details-wrapper .pax-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0px;
    /* border: 1px dashed black; */
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.36);
    border-radius: 10px;
    padding: 30px;
}

.booking-details-wrapper .pax-details-wrapper .pax-details .header{
    font-weight: 600;
    margin-right: 50px;
    font-size: 20px;
    
}

.booking-details-wrapper .pax-details-wrapper .pax-details .details-section{
    display: flex;
    align-items: center;
}

.booking-details-wrapper .pax-details-wrapper .pax-details .firstName-wrapper,
.booking-details-wrapper .pax-details-wrapper .pax-details .lastName-wrapper,
.booking-details-wrapper .pax-details-wrapper .pax-details .birthdate-wrapper,
.booking-details-wrapper .pax-details-wrapper .pax-details .passport-date,
.booking-details-wrapper .pax-details-wrapper .pax-details .nationality,
.booking-details-wrapper .pax-details-wrapper .pax-details .passport-number{
    display: flex;
    align-items: center;
    margin: 10px 20px;
}

.booking-details-wrapper .pax-details-wrapper .pax-details .nationality{
    width: 200px;
}

.booking-details-wrapper .pax-details-wrapper .pax-details .firstName-wrapper .title{
    width: 100px;
}

.booking-details-wrapper .success-wrapper,
.booking-details-wrapper .error-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booking-details-wrapper .success-wrapper .success-msg{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    font-weight: 500;
    color: #006c2c
}

.booking-details-wrapper .success-wrapper .success-pending-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    font-weight: 500;
    color: #ffa630;
}

.booking-details-wrapper .success-wrapper .success-pending-msg .success-msg-subheader{
    font-size: 20px;
}

.booking-details-wrapper .error-wrapper .success-icon,
.booking-details-wrapper .error-wrapper .error-icon{
    margin-top: 50px;
    height: 80px;
}

.booking-details-wrapper .error-wrapper .error-msg{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    font-weight: 500;
    color: #d20f46
}

.booking-details-wrapper .success-wrapper .section{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0px;
}

.booking-details-wrapper .success-wrapper .pnr,
.booking-details-wrapper .success-wrapper .ref{
    font-weight: 500;
    font-size: 20px;
}

.booking-details-wrapper .success-wrapper .booked-pax-wrapper .booked-pax{
    display: flex;
    font-size: 15px;
    margin: 10px;
}

.booking-details-wrapper .success-wrapper .booked-pax-wrapper .booked-pax .pax{
    font-weight: 500;
}

.booking-details-wrapper .success-wrapper .booked-pax-wrapper .booked-pax .pax,
.booking-details-wrapper .success-wrapper .booked-pax-wrapper .booked-pax .pax-name,
.booking-details-wrapper .success-wrapper .booked-pax-wrapper .booked-pax .pax-type,
.booking-details-wrapper .success-wrapper .booked-pax-wrapper .booked-pax .passport,
.booking-details-wrapper .success-wrapper .booked-pax-wrapper .booked-pax .gender{
    margin-right: 20px;
}