.home-wrapper {
    /* background-color: #637d63; */
    height: 100%;
    min-height: 100vh;
    background-image: url(../../assets//bg1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.home-wrapper .content-container{
    display: flex;
    justify-content: center;
    padding: 100px 0px;
}