.adjust-markup-wrapper{
    background-color: #ffffff;
    padding: 20px;
    width: 1150px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.adjust-markup-wrapper .back-btn-wrapper{
    display: flex;
    justify-content: start;
}

.adjust-markup-wrapper .back-btn-wrapper .btn-wrapper{
    width: fit-content;
    cursor: pointer;
}

.adjust-markup-wrapper .back-btn-wrapper .back-btn{
    height: 25px;
}

.adjust-markup-wrapper .agent-details{
    display: flex;
    justify-content: center;
}

.adjust-markup-wrapper .agent-details .details-col{
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0px 100px;
}

.adjust-markup-wrapper .agent-details .details-col .details-field{
    margin: 5px 0px;
}

.adjust-markup-wrapper .agent-details .details-col .details-field span{
    font-weight: 700;
}

.adjust-markup-wrapper .adjust-markup-section{
    margin: 50px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.adjust-markup-wrapper .adjust-markup-section .airline-dd{
    width: 250px;
    margin-bottom: 25px;
}

.adjust-markup-wrapper .current-airline-markup{
    margin-bottom: 25px;
}

.adjust-markup-wrapper .current-airline-markup span{
    font-weight: 700;
}

.adjust-markup-wrapper .adjust-markup-section .adjust-markup-section-element{
    margin: 5px 20px;
    margin-bottom: 25px;
}

