.users-wrapper{
    background-color: #ffffff;
    padding: 20px;
    width: 1150px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.users-wrapper .register-btn-wrapper{
    display: flex;
    justify-content: flex-end;
}

.users-wrapper .heading {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 10px;
}

.users-wrapper .sub-heading{
    font-size: 20px;
    font-weight: 400;
    margin: 20px 0px 10px 0px;
}

.users-wrapper .actions-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.users-wrapper .actions-wrapper .edit-delete-wrapper{
    display: flex;
    justify-content: center;
}

.users-wrapper .add-balance-btn,
.users-wrapper .deduct-balance-btn,
.users-wrapper .adjust-markup-btn,
.users-wrapper .adjust-credit-btn,
.users-wrapper .delete-agent-btn,
.users-wrapper .edit-agent-btn{
    width: 150px;
    margin: 5px;
}