.manage-airlines-wrapper{
    background-color: #ffffff;
    padding: 20px;
    width: 1150px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.manage-airlines-wrapper .back-btn-wrapper{
    display: flex;
    justify-content: start;
}

.manage-airlines-wrapper .back-btn-wrapper .btn-wrapper{
    width: fit-content;
    cursor: pointer;
}

.manage-airlines-wrapper .back-btn-wrapper .back-btn{
    height: 25px;
}

.manage-airlines-wrapper  .heading {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 10px;
}

.manage-airlines-wrapper .current-airlines-currency-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.manage-airlines-wrapper .current-airlines-currency-wrapper .current-airlines-currency{
    display: flex;
    align-items: center;
    margin: 10px 20px;
}

.manage-airlines-wrapper .current-airlines-currency-wrapper .current-airlines-currency .current-airline{
    font-weight: 500;
    margin-right: 40px;
}

.manage-airlines-wrapper .current-airlines-currency-wrapper .current-airlines-currency .current-currency{
    width: 200px;
}

.manage-airlines-wrapper .current-airlines-currency-wrapper .current-airlines-currency .delete-airline-wrapper{
    margin-left: 20px;
}

.manage-airlines-wrapper .add-airline-btn-wrapper{
    margin: 20px 0px;
}

.manage-airlines-wrapper .add-airline-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0px;
}

.manage-airlines-wrapper .add-airline-wrapper .select-airline,
.manage-airlines-wrapper .add-airline-wrapper .select-currency{
    width: 200px;
    margin: 10px 10px;
}