.no-flights-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  padding: 20px 100px;
  /* margin: -100px 0px; */
  border-radius: 20px;
  height: 150px;
  /* width: 100px; */
  color: red;
  font-weight: 500;
  font-size: 30px;
}

.search-result-wrapper {
  display: flex;
  flex-direction: column;

  background-color: rgba(255, 255, 255, 1);
  padding: 20px 50px;
  margin: -100px 0px;
  /* border-radius: 20px; */
  width: 100%;
  height: 100%;
}

.search-result-wrapper .search-flights-wrapper {
  display: flex;
  flex-direction: column;
  /* border-bottom: 1px solid black; */
  padding-bottom: 20px;
  background: -webkit-linear-gradient(left, #f1faee, #a8dadc);
  border-radius: 20px;
}

.search-result-wrapper .search-flights-wrapper .text_input {
  color: white;
  border-color: white;
}

.search-result-wrapper .search-flights-wrapper .search-flights-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-result-wrapper
  .search-flights-wrapper
  .search-flights-section
  .search-origin-section,
.search-result-wrapper
  .search-flights-wrapper
  .search-flights-section
  .search-destination-section,
.search-result-wrapper
  .search-flights-wrapper
  .search-flights-section
  .search-trip-section {
  width: 150px;
  margin: 10px;
}

.search-result-wrapper
  .search-flights-wrapper
  .search-flights-section
  .search-departure-section,
.search-result-wrapper
  .search-flights-wrapper
  .search-flights-section
  .search-return-section {
  margin-top: 7px;
  margin: 10px;
}

.search-result-wrapper
  .search-flights-wrapper
  .search-flights-section
  .add-passenger-type,
.search-result-wrapper
  .search-flights-wrapper
  .search-flights-section
  .search-airline-section {
  width: 120px;
  margin: 10px;
}

.search-result-wrapper
  .search-flights-wrapper
  .search-flights-section
  .add-passenger-type {
  margin: 10px;
}

.search-result-wrapper
  .search-flights-wrapper
  .search-flights-section
.search-btn-wrapper{
  margin-left: 20px;
}

.search-result-wrapper .search-result-cards-wrapper {
  display: flex;
  justify-content: center;
}

.search-result-wrapper .search-result-col {
  display: flex;
  flex-direction: column;
}

.search-result-wrapper .flight-card-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.36);
  margin: 20px 15px;
}

.search-result-wrapper .flight-card-wrapper .flight-section-info{
  display: flex;
  height: 150px;
  border-bottom: 1px solid #e2e2e2;
}

.search-result-wrapper .flight-card-wrapper .flight-section-details-text{
  display: flex;
  justify-content: start;
  font-size: 13px;
  font-family: "Rubik-Regular";
  /* border-bottom: 1px solid #e2e2e2; */
  padding: 5px 15px;
  font-weight: 500;
  cursor: pointer;
}

.search-result-wrapper .flight-card-wrapper .flight-section-details-text :hover{
  /* color: blue; */
  color: #023e8a;
}

.search-result-wrapper .flight-card-wrapper .flight-section-details-text .caret-wrapper{
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.search-result-wrapper .flight-card-wrapper .flight-section-details-text .caret{
  margin-left: 5px;
  width: 5px;
  height: 5px;
  border-top: 1px solid black;
  border-right: 1px solid black;
}

.search-result-wrapper .flight-card-wrapper .flight-section-details-text .caret-open{
  rotate: 135deg;
  transition:all 0.3s ease-out;
  margin-bottom: 1px;
}

.search-result-wrapper .flight-card-wrapper .flight-section-details-text .caret-close{
  rotate: -45deg;
  transition:all 0.3s ease-out;
  margin-top: 3px;
}

.search-result-wrapper .flight-card-wrapper .flight-section-details{
  display: flex;
  height: 250px;
  padding: 10px;
  padding-top: 0px;
}

.search-result-wrapper .flight-card-wrapper .flight-section-details .flexi-fare-card{
  border: 1px solid #e2e2e2;
  width: 160px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  margin: 0px 5px;
  padding: 10px;
}

.search-result-wrapper .flight-card-wrapper .flight-section-details .flexi-fare-card .feature{
  font-size: 10px;
  margin: 3px 0px;
}

.search-result-wrapper .flight-card-wrapper .flight-section-details .flexi-fare-card .feature img{
  width: 12px;
  margin-right: 5px;
}

.search-result-wrapper .flight-card-wrapper .flight-section-details .flexi-fare-card .header{
  font-weight: 500
}

.search-result-wrapper .flight-card-wrapper .flight-section-details .flexi-fare-card .flexi-features{
  padding: 10px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.search-result-wrapper .flight-card-wrapper .flight-section-details .flexi-fare-card .amount{
  background-color: #00144e;
  color: white;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 15px;
 align-self: center;
 cursor: pointer;
}


.search-result-wrapper .flight-card-wrapper .flight-section-details .flexi-fare-card .amount:hover{
  background-color: #457b9d
}

.search-result-wrapper .flight-card-wrapper .flight-name-details {
  border-right: 1px solid #e2e2e2;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Rubik-Regular";
  font-size: 13px;
  color: #333333;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-name-details
  .flight-logo
  img {
  height: 20px;
  width: 20px;
}

.search-result-wrapper .flight-card-wrapper .flight-name-details .seperator {
  margin: 5px 0px;
  border-top: 1px solid #e2e2e2;
  width: 100%;
}

.search-result-wrapper .flight-card-wrapper .booking-class-section {
  border-right: 1px solid #e2e2e2;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Rubik-Regular";
  font-size: 13px;
  color: #333333;
}

.search-result-wrapper .flight-card-wrapper .flight-city-details {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-right: 1px solid #e2e2e2;
  width: 420px;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-left,
.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-left
  .flight-city-code{
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-right
  .flight-city-code{
    display: flex;
    justify-content: start;
    align-items: center;
  }


.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-code {
  color: #999999;
  font-size: 18px;
  font-family: "Rubik-Regular";
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-time {
  color: #333333;
  font-weight: 700;
  font-size: 20px;
  font-family: "Rubik-Medium";
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-left
  .flight-city-time {
  margin-left: 5px;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-right
  .flight-city-time {
  margin-right: 5px;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-name {
  font-family: "Rubik-Regular";
  color: #666666;
  font-size: 12px;
  display: flex;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-left
  .flight-city-name {
  justify-content: right;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-right
  .flight-city-name {
  justify-content: left;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-seperator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 20px;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-seperator
  .line-seperator {
  width: 80px;
  border-bottom: 1px solid #cacaca;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .duration-stop {
  border-left: 1px solid #cacaca;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  padding-left: 10px;
  height: 50px;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-seperator
  .duration {
  font-weight: 600;
  font-size: 15px;
  font-family: "Rubik-Regular";
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-city-details
  .flight-city-seperator
  .stop {
  font-size: 10px;
  font-family: "Rubik-Medium";
  /* font-weight: 600; */
}

.search-result-wrapper .flight-card-wrapper .flight-book-btn-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 15px;
  width: 140px;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-book-btn-section
  .total-fare {
  font-weight: 600;
  font-size: 20px;
  font-family: "Rubik-Regular";
  margin-bottom: 3px;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-book-btn-section
  .total-fare
  .rupeeSvg {
  height: 20px;
  margin-top: -2px;
}

.search-result-wrapper
  .flight-card-wrapper
  .flight-book-btn-section
  .header {
  font-weight: 700;
  font-family: "Rubik-Regular";
}

.search-result-wrapper .book-btn-wrapper {
  height: 100px;
  width: 100%;
  background-color: #023e8a;
  position: sticky;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.search-result-wrapper .book-btn-wrapper .trip-details {
  display: flex;
  align-items: center;
}

.search-result-wrapper .book-btn-wrapper .trip-seperator {
  border-right: 1px dashed white;
  height: 100%;
  margin: 0px 30px;
}

.search-result-wrapper .book-btn-wrapper .trip-details .flight-info {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.search-result-wrapper .book-btn-wrapper .trip-details .flight-info .logo {
  height: 30px;
  width: 30px;
}

.search-result-wrapper .book-btn-wrapper .trip-details .flight-info .logo img {
  height: 30px;
  width: 30px;
}

.search-result-wrapper .book-btn-wrapper .trip-details .time {
  font-size: 20px;
  margin: 0px 20px;
  font-weight: 500;
}

.search-result-wrapper .book-btn-wrapper .trip-details .seperator-wrapper {
  display: flex;
  flex-direction: column;
  width: 100px;
}

.search-result-wrapper
  .book-btn-wrapper
  .trip-details
  .seperator-wrapper
  .seperator {
  border-bottom: 1px solid white;
}

.search-result-wrapper
  .book-btn-wrapper
  .trip-details
  .seperator-wrapper
  .duration,
.search-result-wrapper
  .book-btn-wrapper
  .trip-details
  .seperator-wrapper
  .stops {
  font-size: 15px;
}

.search-result-wrapper .book-btn-wrapper .fare-section {
  display: flex;
  align-items: center;
}

.search-result-wrapper .book-btn-wrapper .fare-section .fare-wrapper {
  margin-right: 150px;
}

.search-result-wrapper
  .book-btn-wrapper
  .fare-section
  .fare-wrapper
  .fare-header {
  font-weight: 500;
}

.search-result-wrapper .book-btn-wrapper .fare-section .fare-wrapper .fare {
  font-size: 25px;
  font-weight: 500;
}

.search-result-wrapper
  .book-btn-wrapper
  .fare-section
  .fare-wrapper
  .rupeeSvgWhite {
  height: 30px;
  margin-top: -2px;
}

.search-result-wrapper .book-btn-wrapper .btn-wrapper {
  height: 20px;
}
