.reports-wrapper {
  background-color: #ffffff;
  width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
}

.reports-wrapper .heading {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 10px;
}

.reports-wrapper .report-client-selection-section,
.reports-wrapper .report-airline-section,
.reports-wrapper .report-origin-section,
.reports-wrapper .report-destination-section,
.reports-wrapper .report-type-dd-section,
.reports-wrapper .start-date-picker-section,
.reports-wrapper .end-date-picker-section {
  width: 260px;
  margin: 15px;
}

.reports-wrapper .report-selection-section,
.reports-wrapper .report-city-section,
.reports-wrapper .report-dates-section {
  display: flex;
}

.reports-wrapper .fetch-report-btn-wrapper{
  margin-bottom: 30px;
}

.reports-wrapper .download-report-btn-wrapper {
  margin: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.reports-wrapper .download-report-btn-wrapper .downloadIcon {
  height: 25px;
  margin-right: 10px;
}
