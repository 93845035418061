.booking-history-wrapper {
  background-color: #ffffff;
  width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
}

.booking-history-wrapper .download-offline-bookings-btn-wrapper{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.booking-history-wrapper .heading {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 10px;
}

.booking-history-wrapper .client-selection-section {
  width: 260px;
  margin: 15px;
}
