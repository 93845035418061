.add-agent-wrapper {
  background-color: #ffffff;
  padding: 20px;
  width: 1150px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.add-agent-wrapper .heading {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 30px;
}

.add-agent-wrapper .add-agent-section {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
}

.add-agent-wrapper .add-agent-section .name-wrapper,
.add-agent-wrapper .add-agent-section .email-wrapper,
.add-agent-wrapper .add-agent-section .phone-wrapper,
.add-agent-wrapper .add-agent-section .password-wrapper,
.add-agent-wrapper .add-agent-section .currency-wrapper {
  margin: 0px 20px;
}

.add-agent-wrapper .add-agent-section .currency-wrapper {
  width: 225px;
}

.add-agent-wrapper .permissions-wrapper {
  display: flex;
}

.add-agent-wrapper .permissions-wrapper .permissions-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 425px;
}

.add-agent-wrapper
  .permissions-wrapper
  .permissions-container
  .switch-label-container {
  width: 400px;
}
