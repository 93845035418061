.add-admin-wrapper {
    background-color: #ffffff;
    padding: 20px;
    width: 1150px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
  }
  
  .add-admin-wrapper .heading {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 30px;
  }
  
  .add-admin-wrapper .add-admin-section {
    display: flex;
    justify-content: center;
    margin: 15px 0px;
  }
  
  .add-admin-wrapper .add-admin-section .name-wrapper,
  .add-admin-wrapper .add-admin-section .email-wrapper,
  .add-admin-wrapper .add-admin-section .phone-wrapper,
  .add-admin-wrapper .add-admin-section .password-wrapper {
    margin: 0px 20px;
  }
  
  .add-admin-wrapper .permissions-wrapper {
    display: flex;
  }
  
  .add-admin-wrapper .permissions-wrapper .permissions-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 425px;
  }
  
  .add-admin-wrapper
    .permissions-wrapper
    .permissions-container
    .switch-label-container {
    width: 400px;
  }
  