.navigation-wrapper {
  background-color: #ffffff;
  border-bottom: 1px solid #e2e2e2;
}

.navigation-wrapper .navigation {
  padding: 10px 15px;
}

.navigation-wrapper .navigation-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px -220px;
}

.navigation-wrapper .navigation-content .nav-logo {

}

.navigation-wrapper .navigation-content .nav-menu {
    display: flex;
    align-items: center;
    /* margin-left: 100px; */
}

.navigation-wrapper .navigation-content .nav-menu .navbar-menu-container{
    display: flex;
    align-items: center;
    list-style-type: none;
    padding-left: unset;
    margin-bottom: unset;
    font-size: 18px;
    font-family: "Poppins", sans-serif;;
}

.navigation-wrapper .navigation-content .nav-menu .navbar-menu-container .nav-item {
    margin: 0px 10px;
    font-weight: 400;
    padding: 5px 0px;
}

.navigation-wrapper .navigation-content .nav-menu .navbar-menu-container .nav-item:hover {
  color: #fe8036;
  border-bottom: 1px solid ;
  border-image: linear-gradient(90deg, rgba(145, 145, 145, 0) 0%, #fe8036 51.56%, rgba(145, 145, 145, 0) 100%);
  border-image-slice: 1;
  font-weight: 500;
}

.navigation-wrapper .navigation-content .nav-right {
    display: flex;
}

.navigation-wrapper .navigation-content .nav-right .nav-username,
.navigation-wrapper .navigation-content .nav-right .nav-balance,
.navigation-wrapper .navigation-content .nav-right .nav-logout,
.navigation-wrapper .navigation-content .nav-right .settings-icon-wrapper {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 0px 10px;
}

.navigation-wrapper .navigation-content .nav-right .nav-username,
.navigation-wrapper .navigation-content .nav-right .nav-balance {
  font-weight: 700;
}

.navigation-wrapper .navigation-content .nav-right .nav-logout .logout-btn {
  /* box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14),
    0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
  background-color: #a7ce3b; */
  background-color: #fe8036;
  color: #ffffff;
  cursor: pointer;
  font-weight: 700;
}

/* .navigation-wrapper .navigation-content .nav-right .nav-logout .logout-btn:hover {
  box-shadow: 0 5px 10px -6px #333;
  background-color: #333;
} */

.navigation-wrapper .navigation-content .nav-right .settings-icon-wrapper img{
  height: 40px;
  width: 40px;
}
